<template>
  <div class='selfie-main-wrapper'>
    <a-row style='display: unset'>
      <a-col>
        <div style='margin-top: 100px'>
          <div>
            <div class='selfie-title'>Let's take a selfie</div>
            <div class='selfie-stepper'>Step 3</div>
            <div class='selfie-camera-enable-location-wrapper'>
              <div class='selfie-option2-wrapper'>
                <div
                  class='selfie-camera-btn-wrapper'
                  style='margin-left: 1rem !important'
                >
                  <a-button class='open-camera-btn' @click='onCLickOpenCamera'
                    >Open Camera</a-button
                  >
                </div>
              </div>
              <div class='enable-location-wrapper'>
                <div class='enable-location-text-wrapper'>
                  <div class='enable-location-text1'>
                    Enable Location?
                    <a-switch
                      size='small'
                      style='float: right; margin-top: 10px'
                      v-model:checked='locationChecked'
                      checked-children='Yes'
                      un-checked-children='No'
                      @change='onChangeSwitch'
                    />
                  </div>
                  <div class='enable-location-text2'>*It is mandatory for compliance</div>
                </div>
                <div class='enable-location-switch-wrapper'></div>
              </div>
            </div>
            <div v-if='cameraVisible' style='margin-top: 10px'>
              <camera
                :resolution='{ width: 200, height: 150 }'
                ref='camera'
                :autoplay='autoplay'
              ></camera>
              <button
                @click='snapshot'
                style='
                  cursor: pointer;
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                  background: white;
                  border: 1px solid black;
                '
              >
                Click
              </button>
            </div>
            <div v-if='imageCaptured' style='margin-top: 10px'>
              <img style='width: 100%' :src='imageCaptured' />
            </div>
            <div class='selfie-btn-wrapper'>
              <a-button type='primary' @click='onClickContinue' :loading="load"
                >Continue</a-button
              >
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
/* eslint-disable */
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Camera from 'simple-vue-camera';
import STAGES from '../../../constants/stages';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

export default {
  components: {
    Camera,
  },
  setup() {
    const store = useStore();
    const load = ref(false);
    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch(
            'onboardingStore/updateStage',
            response.data.data.stage,
          );
          store.dispatch('onboardingStore/updateClientName', response.data.data.prefill.name);
        })
        .catch(() => {});
    });
    const router = useRouter();
    const selfieFileList = ref([]);
    const disable = ref(false);
    const cameraVisible = ref(false);
    const autoplay = ref(false);
    const imageCaptured = ref('');
    const camera = ref(null);
    const geo = { lat: 0, long: 0 };
    const locationChecked = ref(false);
    let buttonClicked = false;
    const onCLickOpenCamera = () => {
      if (locationChecked.value) {
        camera.value = null;
        cameraVisible.value = true;
        autoplay.value = true;
      } else {
        const message = 'Validation error';
        const description = 'Location details are mandatory for compliance. Please enable your location sharing';
        Operations.notificationError(message, description);
      }
    };
    const onUploadSelfie = ({ file }, snapshotUrl) => {
      const data = new FormData();
      data.append('file', file);
      data.append('documentType', 'SELFIE');
      data.append('geo', JSON.stringify(geo));
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      const message = null;
      const description = 'Uploading your selfie...';
      Operations.showNotification(message, description);
      disable.value = true;
      services
        .uploadDocument(data, config)
        .then((uploadResponse) => {
          Operations.showNotification(message, description);
          store.dispatch(
            'onboardingStore/updateVerifyPhoto',
            uploadResponse.data.url,
          );
          disable.value = false;
          buttonClicked = true;
          cameraVisible.value = false;
          imageCaptured.value = snapshotUrl;
        })
        .catch(() => {
          Operations.showNotification(message, description);
          selfieFileList.value = [];
          disable.value = false;
        });
    };
    const snapshot = async () => {
      selfieFileList.value = [];
      const blob = await camera.value?.snapshot({ width: 480, height: 320 });
      const file = new File([blob], 'selfie.png', { type: 'image/png' });
      const url = URL.createObjectURL(blob);
      onUploadSelfie({ file }, url);
    };
    const onClickContinue = () => {
      if (selfieFileList.value.length === 0 && !buttonClicked) {
        const message = null;
        const description = 'Please take a Selfie';
        Operations.notificationError(message, description);
      } else if (!locationChecked.value) {
        const message = 'Validation error';
        const description = 'Location details are mandatory for compliance. Please enable your location sharing';
        Operations.notificationError(message, description);
      } else {
        load.value = true;
        router.push('/onboarding/photo/verification');
        store.dispatch('onboardingStore/updateCurrentStage', STAGES.STAGE_TYPE.SELFIE);
      }
    };
    function onSuccess(position) {
      const { latitude, longitude } = position.coords;
      geo.lat = latitude;
      geo.long = longitude;
    }
    function onError() {
      const message = 'Validation error';
      const description = 'Failed to get your location. Please try again';
      Operations.notificationError(message, description);
    }
    const onChangeSwitch = () => {
      if (locationChecked.value) {
        navigator.geolocation.getCurrentPosition(onSuccess, onError);
      } else {
        cameraVisible.value = false;
        selfieFileList.value = [];
      }
    };
    return {
      onClickContinue,
      selfieFileList,
      onUploadSelfie,
      camera,
      snapshot,
      onCLickOpenCamera,
      cameraVisible,
      autoplay,
      imageCaptured,
      buttonClicked,
      locationChecked,
      onChangeSwitch,
      load,
      disable,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/Selfie.scss';
</style>
